#app {
  background-image: url("./images/background.jpg");
  background-size: cover;
  background-position: center;
  height: 80vh;
  width: 100%;
  filter: brightness(70%);
  /* // opacity: 0.39, */
  position: absolute;
  z-index: -1;
}

.welcomeMsg {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 60vh;
}

#welcome-txt {
  font-size: 6.5rem;
  margin-bottom: 0;
  margin-top: 40px;
  color: rgb(255, 255, 255);
  font-family: Cormorant Garamond, serif;
}

#welcome-under {
  font-size: 2.75rem;
  font-weight: 300;
  word-spacing: 10px;
  margin-top: 0;
  color: rgb(255, 255, 255);
  font-family: Cormorant Garamond, serif;
}

p {
  font-size: 20px;
  margin: 0 5vw 1vh 5vw;
  width: 50%;
  text-align: center;
  line-height: 1.7;
}
.about {
  margin: 18vh 9vh 6vh 9vh;
  display: flex;
  flex-direction: column;
  /* justify-content: space-evenly; */
}
.about .line{
  display: none;
}
.card-wrapper {
  display: flex;
  justify-content: space-evenly;
}
.left {
  display: flex;
  justify-content: center;
  align-items: center;
}
.pictureFeatureLeft {
  background-image: url("./images/flats-pic.jpg");
  background-size: cover;
  background-position: center;
  height: 60vh;
  width: 58vw;
}
.right {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.pictureFeatureRight {
  background-image: url("./images/interior-pic.jpg");
  background-size: cover;
  background-position: center;
  height: 60vh;
  width: 58vw;
}
#titleStyleFeat {
  text-align: center;
  font-size: 36px;
  font-family: Cormorant Garamond, serif;
  color: black;
}

@media only screen and (max-width: 1000px) {
  #app {
    width: 100%;
    height: 45%;
  }
  .welcomeMsg {
    height: 30vh;
  }
  #welcome-txt {
    font-size: 2rem;
  }
  #welcome-under {
    font-size: 1rem;
  }
  h1.title {
    margin-top: 5vh;
  }
  .about {
    padding-top: 5vh;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .about .line{
    display: block;
    margin: 5vh;
  }
  .right {
    display: flex;
    justify-content: center;
  }

  .pictureFeatureLeft {
    display: none;
  }
  .pictureFeatureRight {
    display: none;
  }
}
