.contact-wrapper {
  background-image: url("https://images.unsplash.com/photo-1481018085669-2bc6e4f00eed?crop=entropy&cs=srgb&fm=jpg&ixid=M3w0MjkyMTB8MHwxfHNlYXJjaHw4fHxhaXIlMjBjb25kaXRpb25lciUyMHNlbGxpbmclMjBhbmQlMjBpbnN0YWxtZW50JTIwYXQlMjBjbGllbnRzJTIwaG91c2UuJTIwZm9yJTIwY29sZCUyMGFuZCUyMGhvdCUyMHdlYXRoZXIuJTIwbGFuZGluZyUyMHBhZ2UlMjAufGVufDB8fHx8MTY4OTg1ODU3NHww&ixlib=rb-4.0.3&q=85");

  /* background-color: #1b305a; */
  height: 48vh;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

h1 {
  margin-bottom: 5vh;
}
a {
  color: white;
  font-size: 28px;
}
a:hover {
  color: #222f0b;
}
.contact-details p {
  color: white;
}

.title-text {
  font-size: 28px;
  font-weight: 700;
}
.price-box {
  border: #54751a 3.5px solid;
  margin-left: -30vw;
  height: 8vh;
  width: 15vw;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background-color: white;
}

.price-box:hover {
  cursor: pointer;
  border: none;
}
a .email {
  padding: 10px;
}
.contact-details {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

@media only screen and (max-width: 1000px) {
  .contact-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  p .email-link {
    display: flex;
    justify-self: center;
  }
}
