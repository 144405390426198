.card-wrapper {
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
 
  margin: 2vw 5vw 5vw 5vw;
 
}
.title{
margin-top: 21vh;
  display: flex;
  justify-content: center;
  margin-bottom: 1vh;
  font-family: Cormorant Garamond,serif;
}
.line-wrapper{
  width: 100vw;
  display: flex;
  justify-content: center;
}
.line{
  border-bottom: 1.6px black solid;
  width: 5vw;
}
span {
  margin: 10px;
}

#titleStyle{
  text-align: center;
  font-size: 24px;
  font-family: Cormorant Garamond, serif;
}
#textStyle{
  font-family: Roboto, sans-serif;
  font-weight: 300;
  color: #707070;
}
.serwis-wrapper-media{
  display: none;
  margin: 5vh;
}
@media only screen and (max-width: 1000px) {
  .card-wrapper{
   display: none;
  }
  .serwis-wrapper-media{
    display: flex;
   margin: 0;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .leftStyleText{
   text-align: left !important;
  }
  #titleStyle{
    margin-bottom: 1vh;
    margin-top: 3vh;
  }

  .title{
    padding-top: 5vh;
  }
}