.carousel-bar {
  text-align: center;

  background-color: #164531;
  width: 100%;

}
h3 {
  margin-top: 50px;
  display: flex;
  justify-content: center;
}
.carousel-text {
  margin: 15vh 20vh;
  padding: 0 90px 0 90px;
  width: 80vw;
  color: white;
  font-family: Roboto, sans-serif;
  font-weight: 300;

  
}

@media only screen and (max-width: 1000px){
  .carousel-text{
    margin: 5vh;
  }
}
