.navbar {
  background-color: transparent;
  height: 9vh;
  width: 100vw;
  display: flex;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 2;
  transition: background-color 0.5s ease-in-out;
}
.navbar.scrolled {
  background-color: black;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);
}

ul {
  list-style-type: none;
  display: flex;
  align-self: baseline;
}
li {
  margin: 10px 100px 0 0;
}
a {
  text-decoration: none;
}
.link {
  color: white;
  transition: font-size 0.5s ease-in-out;
}
.link.scrolled {
  font-size: 30px;
}
.logo {
  height: 60px;
  width: 60px;
  margin-left: 1vw;
  margin-bottom: 0.7vh;
  transition: background-color 0.5s ease-in-out;
}
.logo.scrolled {
  background-color: rgba(241, 246, 251, 0.956);
}
